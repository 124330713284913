
import { Component, Prop, Vue } from 'vue-property-decorator'
import { asyncRoutes } from '@/router'
import { State } from 'vuex-class'
import { apiLogout } from '@/api/app'

@Component
export default class Header extends Vue {
  @State('user') user: any
  get activePath() {
    return this.$route.meta?.parentPath
  }

  get menuList() {
    return asyncRoutes
  }

  get userInfo() {
    return this.user.userInfo
  }

  get logo() {
    return this.$store.getters.config.logo
  }
  handleCommand(command: string) {
    switch (command) {
      case 'logout':
        this.logout()
    }
  }
  logout() {
    apiLogout().then(() => {
      this.$store.commit('setUserInfo', {})
      this.$store.commit('setPermission', {})
      this.$router.push('/account/login')
    })
  }
}
