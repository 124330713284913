import Vue from 'vue'
import './plugins/axios'
import './plugins/element'
import './plugins/umy-ui'
import './plugins/echarts'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission'
import 'nprogress/nprogress.css'
import './styles/element-variables.scss'
import appMixin from './mixin/app'
import './directives/action'
import { getImageUri } from './utils/util'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.config.productionTip = false
Vue.use(ViewUI);
Vue.prototype.$getImageUri = getImageUri

Vue.mixin(appMixin)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
