import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [
  {
    path: '/factory',
    name: 'factory',
    meta: { title: '工厂设备' },
    redirect: '/factory/index',
    component: Main,
    children: [
      // start
      {
        path: '/factory/index',
        name: 'imagetru3d',
        meta: {
          title: '设备管理',
          parentPath: '/factory',
          icon: 'icon_yingyongcenter',
        },
        component: Blank,
        redirect: '/factory/imagetru3d/device_info',
        children: [
          {
            path: '/factory/imagetru3d/device_info',
            name: 'device_info',
            meta: {
              title: '设备信息管理',
              parentPath: '/factory',
              permission: ['view'],
            },
            component: () => import('@/views/imagetru3d/device_info.vue'),
          },
          {
            path: '/factory/imagetru3d/device_info_edit',
            name: 'device_info_edit',
            meta: {
              hidden: true,
              title: '新增光场设备',
              parentPath: '/factory',
              prevPath: '/imagetru3d/device_info',
            },
            component: () => import('@/views/imagetru3d/device_info_edit.vue'),
          },
          {
            path: '/factory/imagetru3d/device_category',
            name: 'device_category',
            meta: {
              title: '设备类型管理',
              parentPath: '/factory',
              // icon: 'icon_sort',
              permission: ['view'],
            },
            component: () => import('@/views/imagetru3d/device_category.vue'),
          },
          {
            path: '/factory/imagetru3d/device_category_edit',
            name: 'device_category_edit',
            meta: {
              hidden: true,
              title: '新增设备分类',
              parentPath: '/factory',
              prevPath: '/factory/imagetru3d/device_category',
            },
            component: () =>
              import('@/views/imagetru3d/device_category_edit.vue'),
          },
        ],
      },
      // end
    ],
  },
]

export default routes
