import Main from '@/layout/main.vue'
const routes = [
  {
    path: '/material',
    name: 'material',
    meta: { title: '素材中心' },
    redirect: '/material/material',
    component: Main,
    children: [
      {
        path: '/material/material',
        name: 'material',
        meta: {
          title: '素材中心',
          parentPath: '/material',
          icon: 'icon_dianpu_sucai',
          permission: ['view'],
        },
        component: () => import('@/views/material/material.vue'),
      },
      {
        path: '/material/recycleBin',
        name: 'recycleBin',
        meta: {
          title: '回收站',
          parentPath: '/material',
          // icon: 'icon_dianpu_sucai',
          icon: 'icon_caiwu_tixian',
          permission: ['view'],
        },
        component: () => import('@/views/material/recycle-bin.vue'),
      },
    ],
  },
]

export default routes
