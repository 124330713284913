import request from '@/plugins/axios'

// 登录
export const apiLogin = (data: any) => request.post('/login/account', data)

// 退出登录
export const apiLogout = () => request.get('/login/logout')

// 配置
export const apiconfig = () => request.get('/config/getConfig')

// 退出登录
export const apiDownLoad = () => request.get('', {params: {d: 1}})


// 页面跳转列表
export const apiLinkList = () => request.get('/theme.DecorateThemePage/getshoppage',)

// 权限列表
export const apiAuth = () => request.get('/config/getAuth',)
