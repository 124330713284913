export default {
    // 版本
    version: '1.1.0',
    // 路径
    //生产环境
    //baseURL: 'http://factory.imagetru3d.com',
    //本地服务
    //baseURL: 'http://192.168.1.230:8083',
    //预生产环境代码
    baseURL: 'https://test-factory-service.zx-real.com',
    tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW'
}
